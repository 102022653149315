const data = JSON.stringify({
  total_pages: 5,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Sintomi tipic',
      description: '',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Minzione frequente di piccole quantità di urina (necessità di andare al bagno molto spesso)",
          answers: [
            {
              id: 1,
              desc: "4 o meno al giorno",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "5-6 al giorno",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "7-8 al giorno",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "9-10 o più al giorno",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Minzione urgente (desiderio intenso e incontrollabile di urinare)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Sensazione di dolore o bruciore durante la minzione",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Svuotamento vescicale incompleto dopo la minzione",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Dolore o pressione fastidiosa nella parte bassa dell’addome (area sovrapubica)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "Sangue visibile nelle tue urine",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: 'Differenziali',
      description: '',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "Dolore lombare* (parte bassa della schiena) * spesso unilaterale (su un lato)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "Secrezioni vaginali (soprattutto al mattino)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "Secrezioni uretrali (senza minzione)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "Temperatura corporea elevata (brividi/febbre) (indicare X se misurata)",
          answers: [
            {
              id: 1,
              desc: "≤37.5 °C",
              name: "No",
              points: 0
            },
            {
              id: 2,
              desc: "37.6-37.9 °C",
              name: "Sì, lieve",
              points: 1
            },
            {
              id: 3,
              desc: "38.0-38.9 °C",
              name: "Sì, moderato",
              points: 2
            },
            {
              id: 4,
              desc: "≥39.0 °C",
              name: "Sì, grave",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: 'Qualità‘ della vita',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "Prego dare un punteggio complessivo su quanto i sintomi precedentemente menzionati ti hanno infastidita nelle ultime 24 ore (segnare una sola risposta)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nessun fastidio avvertito (nessun sintomo, stata bene come al solito)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Sensazione di fastidio lieve (sensazione di qualcosa di peggiore rispetto al solito)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Sensazione di fastidio moderato (sensazione abbastanza cattiva)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sensazione di fastidio estremo (sensazione terribile)",
              points: 3
            }
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: "Prego scegliere il numero che maggiormente descrive quanto il tuo normale lavoro/attività quotidiane sono influenzate dai tuoi sintomi, sopra menzionati, nelle ultime 24 ore (segnare una sola risposta)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nessuna variazione (attività quotidiane svolte normalmente)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Alterate lievemente (attività quotidiane svolte con qualche difficoltà)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Alterate moderatamente (attività quotidiane svolte con sforzo significativo)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Alterate fortemente (attività quotidiane pressoché impossibili da svolgere)",
              points: 3
            }
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "Indicare quanto le tue attività sociali sono state influenzate dai sintomi precedentemente menzionati nelle ultime 24 ore",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nessuna modifica (attività sociali svolte normalmente)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Alterate lievemente (impossibilità di svolgere qualche attività sociale)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Alterate moderatamente (capacità di svolgere solo qualche attività sociale)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Alterate estremamente (impossibilità di svolgere nessuna attività sociale – i sintomi mi tengono “prigioniera,, in casa)",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: 'Addizionale',
      description: '',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "Mestruazioni ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Si",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "Sintomi premestruali ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Si",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "Sintomi della menopausa ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Si",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "Sei incinta ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Si",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "Hai il diabete mellito ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "No",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Si",
              points: "yes"
            }
          ]
        }
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
